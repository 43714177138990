// newsletter signup submission
export function initHubspotForms() {  
  const hubspotForms = document.querySelectorAll('.hs-form') as NodeListOf<HTMLFormElement>;
  hubspotForms.forEach(form => {
    form.addEventListener('submit', (event) => {
      const formEl = event.target as HTMLFormElement;
      if (!formEl) return 

      event.preventDefault();
      const formData = new FormData(formEl)
      const fields = convertFormDataToFields(formData);
      if (fields.length === 0) {
        event.stopImmediatePropagation();
        window.notificationsManager.open({
          type: window.notificationsManager.types.NOTIFICATION_ERROR,
          body: "Somethign went wrong. Please try again."
        });
        return;
      }
      try {
        fetch(formEl.action, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            fields,
            context: {
              pageUri: window.location.href,
              pageName: document.title
            }
          })
        })
        .then(async (response: Response) => {
          const respJson = await response.json()
          if(response.status == 200) { 
            if (respJson.inlineMessage) {
              window.notificationsManager.open({
                type: window.notificationsManager.types.NOTIFICATION_SUCCESS,
                body: 'Thank you for your submission!'
              });
            } else {
              window.location.assign(respJson.redirectUri);
            }
          } else { 
            window.notificationsManager.open({
              type: window.notificationsManager.types.NOTIFICATION_ERROR,
              body: "Somethign went wrong. Please try again."
            });
          }})
      } catch (error) {
        window.notificationsManager.open({
          type: window.notificationsManager.types.NOTIFICATION_ERROR,
          body: "Somethign went wrong. Please try again."
        });
      }
    });
  });
}

function convertFormDataToFields(formData: FormData) {
  let fields = [];
  for (const entry of formData.entries()) {
    if (entry[1] && entry[1] !== '') {
      fields.push({
        'name': entry[0],
        'value': entry[1]
      });
    }
  }
  return fields;
}

document.addEventListener('DOMContentLoaded', () => {
  initHubspotForms();
})